<template>
  <vs-row vs-w="12" v-show="!loading">
    <vs-col vs-w="12" class="mb-base">
      <vx-card>
        <vs-row vs-w="12">
          <h4 class="mb-5">Promo Rate Plan</h4>
        </vs-row>

        <vs-row vs-w="12">
          <!-- Custom name -->
          <vs-col vs-w="12" class="mb-5">
            <vs-input
              class="w-full"
              label="Custom Name"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <!-- Discount -->
          <vs-col vs-w="2" class="mb-5">
            <span class="block mb-3 text-sm">
              Discount
              <span class="text-sm font-medium">( {{ this.propertyPromotion.min_discount }}% ~ {{ this.propertyPromotion.max_discount }}% )</span>
            </span>
            <vs-input-number
              @keyup="handleDiscountInput"
              class="justify-between w-8/12"
              :min="propertyPromotion.min_discount"
              :max="propertyPromotion.max_discount"
              v-model="payload.discount"
            />
          </vs-col>

          <!-- Bookable Periode Date -->
          <vs-col vs-w="12" class="mb-5">
            <span class="mb-2 block text-sm">Bookable Periode</span>
            <flat-pickr
              class="w-full"
              :config="bookablePeriodeConfig"
              v-model="payload.bookablePeriode"
            />
          </vs-col>

          <!-- Stay Dates -->
          <vs-col vs-w="12">
            <span class="mb-2 block text-sm">Stay Dates</span>
            <flat-pickr
              class="w-full"
              :config="stayDatesConfig"
              v-model="payload.stayDates"
            />
          </vs-col>
        </vs-row>
      </vx-card>
    </vs-col>

    <vs-col vs-w="12" class="mb-base">
      <vx-card>
        <vs-row vs-w="12">
          <!-- Rate plan selection -->
          <vs-col vs-w="12" class="mb-base">
            <span class="mb-2 block text-sm">Rate Plans</span>

            <div class="vx-row">
              <div
                class="vx-col w-full md:w-6/12 lg:w-4/12 mb-4"
                v-for="ratePlan in ratePlanOptions"
                :key="ratePlan.id"
              >
                <vs-checkbox
                  :disabled="ratePlan.product_properties.length < 1"
                  :vs-value="ratePlan.id"
                  v-model="selectedRatePlans"
                >
                  {{ ratePlan.name }}
                </vs-checkbox>
              </div>
            </div>
          </vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col
            vs-w="12"
            class="mb-5"
            v-for="(
              selectedRatePlan, ratePlanIndex
            ) in payload.property_rate_promotions"
            :key="ratePlanIndex"
          >
            <vx-card>
              <vs-row vs-w="12">
                <vs-col vs-w="12" class="mb-3">
                  <span class="text-lg font-medium">{{
                    selectedRatePlan.name
                  }}</span>
                </vs-col>
                <vs-col
                  vs-w="4"
                  v-for="(
                    productProperty, propertyIndex
                  ) in ratePlanOptions.find(
                    (ratePlan) => ratePlan.id === selectedRatePlan.id
                  ).product_properties"
                  :key="propertyIndex"
                >
                  <vs-checkbox
                    :vs-value="productProperty"
                    v-model="
                      payload.property_rate_promotions[ratePlanIndex]
                        .product_properties
                    "
                    >{{
                      productPropertyOptions.find(
                        (property) => property.id === productProperty
                      ).name
                    }}</vs-checkbox
                  >
                </vs-col>
              </vs-row>
            </vx-card>
          </vs-col>
        </vs-row>
      </vx-card>
    </vs-col>

    <vs-col vs-w="12">
      <vs-button class="w-full" @click="storeData">Submit</vs-button>
    </vs-col>
  </vs-row>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

// Store Module
import modulePromoRatePlanManagement from "@/store/promo-rate-plan-management/modulePromoRatePlanManagement.js";

export default {
  name: "AddPromoRatePlan",

  metaInfo: {
    title: "Add Promo Rate Plan",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    "v-select": vSelect,
    flatPickr,
  },

  watch: {
    // property promotion
    propertyPromotion(property) {
      // bookable periode setting
      this.bookablePeriodeConfig.minDate = this.$moment().format("YYYY-MM-DD");
      if (property.book_end_date != null) {
        this.bookablePeriodeConfig.maxDate = property.book_end_date;
      }

      // stay dates setting
      this.stayDatesConfig.minDate = this.$moment().format("YYYY-MM-DD");
      if (property.stay_end_date != null) {
        this.stayDatesConfig.maxDate = property.stay_end_date;
      }
    },

    selectedRatePlans(selectedRatePlans) {
      this.payload.property_rate_promotions = [];
      const ratePlans = this.ratePlans.filter((ratePlan) =>
        selectedRatePlans.includes(ratePlan.id)
      );
      ratePlans.forEach((selectedRatePlan) =>
        this.payload.property_rate_promotions.push(selectedRatePlan)
      );
    },
  },

  computed: {
    propertyPromotionID() {
      return this.$secure.decrypt(this.$route.params.propertyPromotionID);
    },
  },

  data() {
    return {
      propertyPromotion: {},
      ratePlans: [],

      payload: {
        property_rate_promotions: [],
        name: "",
        bookablePeriode: "",
        stayDates: "",
        discount: 0,
      },

      selectedRatePlans: [],

      // Options
      ratePlanOptions: [],
      productPropertyOptions: [],

      // State
      loading: true,

      // flatPickr config
      bookablePeriodeConfig: {
        mode: "range",
        dateFormat: "Y-m-d",
        minDate: null,
        maxDate: null,
      },
      stayDatesConfig: {
        mode: "range",
        dateFormat: "Y-m-d",
        minDate: null,
        maxDate: null,
      },
    };
  },

  methods: {
    handleDiscountInput(e) {
      if (e.keyCode !== 8 && e.keyCode !== 46) {
        if (this.payload.discount > this.propertyPromotion.max_discount) {
          this.payload.discount = this.propertyPromotion.max_discount;
        }

        if (this.payload.discount < this.propertyPromotion.min_discount) {
          this.payload.discount = this.propertyPromotion.min_discount;
        }
      }
    },

    // get all rate plans
    getRatePlans() {
      axios
        .get(`vancynet/rate-plan`)
        .then(({ data: res }) => {
          this.ratePlanOptions = res.data.map((ratePlan) => {
            return {
              id: ratePlan.id,
              name: ratePlan.name,
              product_properties: ratePlan.product_properties.map(
                (productProperty) => productProperty.id
              ),
            };
          });

          this.ratePlans = res.data.map((ratePlan) => {
            return {
              id: ratePlan.id,
              name: ratePlan.name,
              product_properties: ratePlan.product_properties.map(
                (productProperty) => productProperty.id
              ),
            };
          });
        })
        .catch((err) => this.$catchErrorResponse(err));
    },

    // get all product properties
    getProductProperties() {
      axios
        .get(`vancynet/product-property`)
        .then(({ data: res }) => {
          this.productPropertyOptions = res.data.map((property) => {
            return {
              id: property.id,
              name: property.name,
            };
          });
        })
        .catch((err) => this.$catchErrorResponse(err));
    },

    // get property promotion based on user select
    getPropertyPromotion() {
      axios
        .get(`property-promotion/${this.propertyPromotionID}`)
        .then(({ data: res }) => {
          this.propertyPromotion = res.data;
          this.payload.discount = res.data.min_discount
        })
        .catch((err) => this.$catchErrorResponse(err));
    },

    // payload generator
    generatePayload() {
      // Set book start and end date
      const bookStartDate = this.payload.bookablePeriode.split(" to ")[0];
      const bookEndDate = this.payload.bookablePeriode.split(" to ")[1];

      // Set stay start and end date
      const stayStartDate = this.payload.stayDates.split(" to ")[0];
      const stayEndDate = this.payload.stayDates.split(" to ")[1];

      // remove unused keys
      delete this.payload.bookablePeriode;
      delete this.payload.stayDates;

      // assign usable properties to the payload
      const payload = Object.assign(this.payload, {
        property_promotion_id: this.propertyPromotionID,
        book_start_date: bookStartDate,
        book_end_date: bookEndDate,
        stay_start_date: stayStartDate,
        stay_end_date: stayEndDate,
      });

      return payload;
    },

    storeData() {
      const payload = this.generatePayload();

      this.$store
        .dispatch("promoRatePlanManagement/storePromoRatePlan", payload)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success",
          });

          this.$router.push({ name: "vancynet-promo-rate-plan-index" });
        })
        .catch((err) => this.$catchErrorResponse(err));
    },
  },

  created() {
    if (!modulePromoRatePlanManagement.isRegistered) {
      this.$store.registerModule(
        "promoRatePlanManagement",
        modulePromoRatePlanManagement
      );
      modulePromoRatePlanManagement.isRegistered = true;
    }

    this.$vs.loading({
      type: "sound",
    });

    Promise.all([
      this.getRatePlans(),
      this.getProductProperties(),
      this.getPropertyPromotion(),
    ])
      .then(() => {})
      .catch((err) => this.$catchErrorResponse(err))
      .finally(() => {
        this.loading = false;
        this.$vs.loading.close();
      });
  },
};
</script>

<style lang="scss">
.con-vs-radio,
.con-vs-checkbox {
  display: flex !important;
  align-items: center !important;
}

.con-vs-checkbox span {
  min-width: 20px !important;
  min-height: 20px !important;
}
</style>
